// All sections are expected to have the following structure:
//    <section id="section-*">
//      <div class="section-inner">...<div>
//    </section>
//
// Index:
//  - Horizontal section padding
//  - Section Modifiers
//  - Homepage sections
//  - Flavors Landing Page sections
//  - Other pages sections
//


//
// HORIZONTAL SECTION PADDING
//
// We set 1.5rem as the minimum total X padding.
// For the sections with columns, since the already have X padding (--bs-gutter-x), we only add additional padding to get to the 1.5rem.
// If the column gutters are already more than 1.5rem on wide screens, the math still works, resulting in negative padding
// to be added, and since there is no such thing in browsers, the additional padding = 0.
//
@include media-breakpoint-up(lg) {
  [id^="section-"] .section-inner.row { --bs-gutter-x: 3rem }
}
@include media-breakpoint-up(xl) {
  [id^="section-"] .section-inner.row { --bs-gutter-x: 6rem }
}

.section-inner p:last-child { margin-bottom: 0 }
.section-inner {
  --px: 1.5rem;
  --px-row: var(--bs-gutter-x) * .5;

  &:not(.row) { padding-inline: var(--px) }
  &.row { padding-inline: calc(var(--px) - var(--px-row)) }
}


//
// SECTION MODIFIERS
//
.bg-fruits-image {
  background: url(/assets/images/explore.jpg) center;
  background-size: cover;
}


//
// HOMEPAGE SECTIONS
//
#section-homepage-1 {
  position: relative;
  .section-inner { padding-block: 1rem 2rem }

  @include media-breakpoint-up(lg) {
    .section-inner { padding-bottom: 3rem }
  }
}


#section-homepage-2 {
  --py: 2rem;

  padding-block: calc(var(--swoop-green-v1-bottom-height) + var(--py)) calc(var(--swoop-green-v2-top-height) + var(--py));

  .section-inner {
    --bs-gutter-x: 6rem;
    --bs-gutter-y: 2rem;
  }

  @include media-breakpoint-up(md) {
    --py: 4rem;
    .section-inner { --bs-gutter-x: 4rem }
  }
  @include media-breakpoint-up(lg) {
    .section-inner {
      div:first-child { padding-left: 0 }
      div:last-child { padding-right: 0 }
    }
  }
}


#section-homepage-3 {
  .section-inner { padding-block: 1rem var(--swoop-blue-top-height) }
  .col-text { margin-top: 1rem }
  .col-image { z-index: 1 }

  @include media-breakpoint-up(md) {
    .section-inner { padding-bottom: calc(var(--swoop-blue-top-height) - 3vw) }
    .col-text { margin-top: 0 }
  }
  @include media-breakpoint-up(lg) {
    .section-inner { padding-top: 3rem }
  }
}


#section-homepage-4 {
  position: relative;

  .section-inner { padding-block: 1rem 2rem }
  .col-text { margin-top: 1rem }

  @include media-breakpoint-up(md) {
    .section-inner { padding-block: 0 }
    .col-text {
      order: -1;
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: -2rem;
    .col-image { margin-top: -10% }
  }
}


#section-homepage-5 {
  .section-inner { padding-block: 3rem var(--swoop-green-v2-top-height) }
  .col-text { margin-top: 6% }
  .col-image { z-index: 1 }

  @include media-breakpoint-up(md) {
    .section-inner { padding-top: 2rem }
  }
}


#section-homepage-6 {
  .section-inner { padding-bottom: 3rem }
  .col-text { margin-top: 1rem }

  @include media-breakpoint-up(md) {
    margin-top: -4rem;
    .col-text {
      order: -1;
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: -7rem;
  }
}


//
// FLAVORS LANDING PAGE SECTIONS
//
#section-flavors-2 {
  .section-inner { padding-bottom: var(--swoop-blue-top-height) }
  .col-image { z-index: 1 }

  @include media-breakpoint-up(md) {
    .section-inner { padding-top: 4rem }
  }
  @include media-breakpoint-up(lg) {
    .section-inner { padding-block: 3rem 0 }
    .col-text { margin-top: 2rem }
  }
}


#section-flavors-3 {
  .section-inner { padding-block: 1rem var(--swoop-green-v2-top-height) }
  .col-text { margin-top: 1rem }

  @include media-breakpoint-up(md) {
    .section-inner { padding-top: 0 }
    .col-text {
      order: -1;
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    .section-inner { padding-bottom: calc(var(--swoop-green-v2-top-height) + 1rem) }
    .col-image { margin-top: -9% }
  }
}


#section-flavors-4 {
  position: relative;

  .section-inner { padding-block: 1rem 2rem }
  .col-text { margin-top: 1rem }

  @include media-breakpoint-up(md) {
    .col-text { margin-top: 0 }
  }
  @include media-breakpoint-up(lg) {
    .col-image { margin-top: -6% }
  }
}


//
// OTHER PAGES SECTIONS
//
#section-intro {
  .section-inner { padding-block: var(--main-pt-small) 2rem }

  @include media-breakpoint-up(md) {
    .section-inner { padding-bottom: 1rem }
  }
  @include media-breakpoint-up(lg) {
    margin-top: calc((var(--main-pt-small) + var(--swoop-green-v2-top-height)) * -1);
    .section-inner { padding-block: calc(var(--main-pt-full) - var(--main-pt-small) + 1rem) 3rem }
  }
}


// The intro section on the Flavor Finder page.
#section-intro-flavor {
  --swoop-white-top-height: 46px;

  @extend #section-intro;
  .section-inner { padding-block: calc(var(--main-pt-small) + 1rem) calc(var(--swoop-white-top-height) + 1rem) }

  @include media-breakpoint-up(md) {
    .section-inner { padding-bottom: calc(var(--swoop-white-top-height) + 2rem) }
  }
  @include media-breakpoint-up(lg) {
    .section-inner { padding-block: calc(var(--main-pt-full) - var(--main-pt-small) + 1rem) calc(var(--swoop-white-top-height) + 3rem) }
  }
}


// The main content section between the #section-intro and the footer.
#section-content .section-inner {
  padding-bottom: 3rem;
  @include media-breakpoint-up(lg) { padding-bottom: 5rem }
}

#section-content:not(.swoop-white-top) {
  .section-inner { padding-top: calc(var(--swoop-green-v1-bottom-height) + 1rem) }
}

#section-content.swoop-white-top {
  .section-inner { padding-top: 1rem }
}
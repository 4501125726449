@use 'sass:color';

@import "variables";

// Configuration
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/utilities";

$theme-colors: map-merge($theme-colors, ("teal": $teal));

// Layout & components
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/breadcrumb";
@import "node_modules/bootstrap/scss/pagination";
@import "node_modules/bootstrap/scss/carousel";

// Helpers
@import "node_modules/bootstrap/scss/helpers/stacks";
@import "node_modules/bootstrap/scss/helpers/vr";

// Utilities
@import "node_modules/bootstrap/scss/utilities/api";

@import "header";
@import "swoops";
@import "decorations";
@import "sections";
@import "homepage";
@import "flavors";
@import "forms";
@import "footer";
@import "common";


@each $breakpoint, $container-max-width in $container-max-widths {
  .max-width-#{$breakpoint} {
    max-width: $container-max-width;
    margin-right: auto;
    margin-left: auto;
  }
}

.bg-green-light { background-color: $green-light }
.bg-blue-light { background-color: $blue-light }
.bg-purple { background-color: $purple }
.text-teal { color: $teal }
.text-purple { color: $purple }
.border-white-transparent { border: .75rem solid rgba(255 255 255 / .6) }
.border-green-light { border: .75rem solid $green-light }
.highlight-red { color: $red }

img {
  @extend .img-fluid;
}

body {
  padding-left:0;
  padding-right:0;
  padding-top:0;
}

// <main> styles allow it making a wave on top of the footer.
main {
  position: relative;
  z-index: 1;
}

.headline-italic {
  font-family: 'EB Garamond', serif;
  font-size: .85em;
}

.heading-explore {
  font-weight: bold;
  color: $purple;
  text-transform: lowercase;

  &:after {
    content: '›';
    font-size: 1.2em;
    font-weight: normal;
    vertical-align: text-bottom;
    margin-left: .5rem;
  }
}


.breadcrumb {
  flex-wrap: nowrap;
  white-space: nowrap;
}
.breadcrumb-item.active {
  overflow-x: hidden;
  text-overflow: ellipsis;
}


.inside-page-left {
  position: relative;
}
.inside-backgroundImage {
  position: absolute;
  top: -5rem;
  left: -5rem;
}
.inside-featuredImage {
  position: relative;
}

.with-frame {
  border: $green-light .25em solid;
}


//
// SPOTLIGHT
// Spotlight block at the bottom of the pages, the content of which can be specified in the Dashboard by picking one
// of the preexisting entries from the Spotlights section.
//
.inside-brochureDownload {
  background: $green-light;
  max-width:600px;
  padding:20px;
  position:relative;
  z-index:4;
}
.inside-brochureDownload-image-container .inside-brochureDownload-link {
  display:block;
  padding-right:10px;
}
.inside-brochureDownload-image {
  max-width:100%;
}
.inside-brochureDownload-text-container {
  text-align:center;
}
.inside-brochureDownload-text-container .inside-brochureDownload-link {
  display:block;
}
.inside-brochureDownload-text strong.abelei {
  color: $red;
  font-family:"Century Gothic","CenturyGothic","Calibri",sans-serif;
}
.inside-brochureDownload-link:hover {
  text-decoration:none;
}
.inside-brochureDownload-button {
  background:$purple;
  /*color:#000;*/
  color:white;
  display:inline-block;
  font-size:26px;
  line-height:42px;
  height:46px;
  margin-bottom:5px;
  padding:2px 20px 0 20px;
  text-transform:uppercase;
}
.inside-brochureDownload-text {
  color:#000;
  display:block;
  font-size:1.6em;
  line-height:1.1em;
  text-align:center;
}


.featured-image {
  margin-bottom: $spacer * 2;
}
.post {
  &:not(:last-of-type) {
    margin-bottom: map-get($spacers, 5);
  }
}

.post-author {
  /* Same font-size as in the footer */
  font-size: .9rem;
}
.post-separator {
  width: 50%;
  color: $gray-500;
}

@include media-breakpoint-up(lg) {
  .post-intro figure {
    max-width: calc(map-get($container-max-widths, lg) / 2);
  }
}


.pagination {
  font-size: $font-size-lg;
  .page-item.active .page-link {
    border-bottom: $pagination-border-width solid $pagination-border-color;
  }
}
.page-link {
  border: none;
  margin-left: .25rem;
  margin-right: .25rem;
}



// Buttons Row
.buttons-row {
  display: flex;
  flex-direction: column;

  > :not(:last-child) { margin-bottom: .5rem }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    > :not(:first-child) { margin-left: 1rem }
    > :not(:last-child) {
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
}



// A list with drop icons.
// --------------------------------------------------------------
ul.with-drop {
  @extend .list-unstyled;

  li {
    position: relative;
    padding-top: 1px;
    padding-left: 24px;
    margin-bottom: 4px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &.purple-drop li:before { background-image: url(/assets/images/inside/FlavorDrop_purple.png); }
  &.red-drop li:before { background-image: url(/assets/images/inside/FlavorDrop_red.png); }
  &.brown-drop li:before { background-image: url(/assets/images/inside/FlavorDrop_brown.png); }
  &.teal-drop li:before { background-image: url(/assets/images/inside/FlavorDrop_teal.png); }

  a {
    font-size: 1.2em;
    color: black;
    text-transform: uppercase;
  }
}
// END



.carousel-indicators [data-bs-target] {
  border: 2px solid #711c66;
  border-radius: 50%;
  transition: background-color var(--transition-duration);

  &:not(.active) { background-color: transparent }
}

.abelei-pronunciation {
  --transcription-height: 59px;

  position: relative;
  display: inline-block;
  font-size: 1.25em;
  font-family: 'Century Gothic', 'CenturyGothic', 'Calibri', sans-serif;
  margin-top: var(--transcription-height);

  &:before {
    content: '';
    position: absolute;
    width: 167px;
    height: var(--transcription-height);
    top: -2em;
    background: url(../images/pronunciation.png) top right no-repeat;
  }
}


/** ROUND IMAGES
 */
.round-image {
  display: block;

  > img {
    border-radius: 50%;
    border: 0.5rem solid #a1cc45;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
}


/** TRANSPARENT PANEL
 */
.panel-transparent {
  padding: 2rem;
  background-color: rgb(255 255 255 / .85);
}

a.panel-transparent {
  display: block;
  color: $body-color;
  transition: background-color var(--transition-duration);

  &:hover {
    text-decoration: none;
    background-color: white;
  }
}

.panel-transparent.with-border {
  border: 6px solid white;
}


//
// CARD V1
// A card with an overlay image and purple title.
// Used for the Industries Served page.
//
.card-v1 {
  border-color: $green-light;
  transition: border-color var(--transition-duration);

  .card-img {
    aspect-ratio: 1/.65;
    object-fit: cover;
  }
  .card-img-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-text {
    width: 90%;
    font-size: 1.3rem;
    font-weight: bold;
    color: white;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    padding-block: $btn-padding-y;
    background-color: rgba($purple, .80);
  }
  .card-img-overlay, .card-text { transition: background-color var(--transition-duration) }
}
a.card-v1:hover {
  .card-text { background-color: transparent }
  .card-img-overlay { background-color: rgba($purple, .80) }
}


.related-post {
  .image {
    max-width: 120px;
    aspect-ratio: 4/3;
    object-fit: cover;
  }
  .title { color: $teal }
  .date { color: $gray-500 }
}
a.related-post {
  text-decoration: none;
  .image { transition: border-color var(--transition-duration) }

  &:hover {
    .image { border-color: color.adjust($green-light, $lightness: -15%) }
    .title { text-decoration: underline }
  }
}


//
// CMS CONTENT
//
.wysiwyg-content {
  h1 { @include font-size($font-size-base * 2.5) }
  h2 { @include font-size($font-size-base * 2) }
  h3 { @include font-size($font-size-base * 1.75) }
  h4 { @include font-size($font-size-base * 1.5) }
}
#homepage-carousel {
  @extend #section-intro;

  .carousel-indicators { bottom: 4% }

  .carousel-item img {
    width: 100%;
    min-height: 250px;
    max-height: 600px;
    object-fit: cover;
    object-position: top;
  }

  a.carousel-item:hover {
    .heading-explore { color: $purple-light }
  }

  .carousel-caption {
    width: 100%;
    top: 40%;
    right: unset;
    left: 10%;
    text-align: left;
  }
  .carousel-caption-inner {
    max-width: 18rem;
    color: $body-color;

    .heading-explore { transition: color var(--transition-duration) }
  }
}
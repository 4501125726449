:root {
  --flavor-filters-icon-mr: .75em;
}

//
// FLAVOR FILTERS
//
#flavor-filters-container {
  .flavor-filters-menu {
    padding-block: 1rem 2rem;
    padding-inline: 2rem;
  }

  @include media-breakpoint-up(lg) {
    --drop-height: 2.5rem;
    --drop-mt: .5rem;

    position: relative;
    padding-top: calc(var(--drop-height) + var(--drop-mt));

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: var(--drop-mt);
      left: var(--drop-mt);
      height: var(--drop-height);
      aspect-ratio: 240/135;
      background: url(/assets/images/flavor-drops-2.svg);
    }
  }
}


#flavor-filters-container [data-bs-toggle], #flavors-search-input {
  background-size: 1em;
  background-repeat: no-repeat;
  background-position: calc(100% - var(--flavor-filters-icon-mr));
  padding-right: calc(var(--flavor-filters-icon-mr) + #{$input-padding-x});
}

#flavor-filters-container [data-bs-toggle] {
  @include font-size($input-font-size);
  line-height: $input-line-height;
  padding: $input-padding-y $input-padding-x;
  border: 1px solid transparent;
  background-image: url(/assets/images/icon-expand.svg);
}

#flavors-search-input {
  background-image: url(/assets/images/icon-search.svg);
  background-color: $green-light;
}


#no-search-results { display: none }

.flavors-category { width: 100% }
.flavors-list { columns: 1 }

@include media-breakpoint-up(sm) {
  .flavors-list { columns: 2 }
}
@include media-breakpoint-up(sm) {
  .flavors-list { columns: 3 }
}
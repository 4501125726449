#footer {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: 0;
    background: url(/assets/images/purple-stripes.svg) center repeat-x;
    background-size: auto 100%;
  }
}

#footer-logo {
  width: 100px;
  margin-top: 12px;
}

#footer-contact, #footer-copyright { font-size: .9em }
#footer-contact, #footer-contact span a { color: white }
#footer-copyright { text-align: center }
#footer-copyright, #footer-copyright span a { color: #ca50e4 }

@include media-breakpoint-down(lg) {
  #footer-contact {
    flex-direction: column;
    gap: 0 !important;

    .vr { display: none }
  }
}


/** SOCIAL LINKS ROW
 */
.social-links {
  display: flex;

  > *:not(:first-child) { margin-left: .5rem; }
  > *:not(:last-child) { margin-right: .5rem; }

  a {
    &:hover > img {
      opacity: .6;
      //filter: invert(1) sepia(1) saturate(5) hue-rotate(20deg);
    }
    > img { height: 1.8rem }
  }
}

#footer .social-links {
  margin-top: .6rem;
  justify-content: center;
}
:root {
  // These variables are used in the _sections.scss, so, this file must be included before the _sections.scss.
  --swoop-green-v1-bottom-height: 44px;
  --swoop-green-v2-top-height: 61px;
  --swoop-blue-top-height: 61px;
}


.swoop-green-v1, .swoop-green-v2, .swoop-blue, .swoop-green-content {
  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: var(--height);
    background-repeat: repeat-x;
    background-position: center;
    background-size: auto 100%;
  }
  &:before { margin-top: calc(var(--height) * -1) }
  &:after { margin-bottom: calc(var(--height) * -1) }
}


.swoop-green-v1 {
  background-color: $green-light;
  &:before {
    --height: 46px;
    background-image: url(/assets/images/swoop-green-v1-top.svg);
  }
  &:after {
    --height: var(--swoop-green-v1-bottom-height);
    background-image: url(/assets/images/swoop-green-v1-bottom.svg);
  }
}


.swoop-green-v2 {
  background-color: $green-light;
  &:before {
    --height: var(--swoop-green-v2-top-height);
    background-image: url(/assets/images/swoop-green-v2-top.svg);
  }
  &:after {
    --height: 28px;
    background-image: url(/assets/images/swoop-green-v2-bottom.svg);
  }
}


.swoop-blue {
  background-color: $blue-light;
  &:before {
    --height: var(--swoop-blue-top-height);
    background-image: url(/assets/images/swoop-blue-top.svg);
  }
  &:after {
    --height: 32px;
    background-image: url(/assets/images/swoop-blue-bottom.svg);
  }
}


.swoop-green-content {
  background: linear-gradient(0deg, $green-gradient 0%, transparent 50%);
  &:after {
    --height: 28px;
    background-image: url(/assets/images/swoop-green-content-bottom.svg);
  }
}

.swoop-white-top {
  &:before {
    --height: 46px;
    background-image: url(/assets/images/swoop-white-top.svg);
  }
}
// Index:
//  - Circle Decorations
//  - Inline Decorations
//

//
// CIRCLE DECORATIONS
// Decorations that are used for the round images on the landing pages.
//
.decoration {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    display: block;
  }
}

.decoration.v1 {
  --width: 27%;
  --offset: 5%;
  --margin: calc(var(--width) - var(--offset));

  padding-right: var(--margin);

  &:after {
    width: var(--width);
    aspect-ratio: 175/268;
    top: 0;
    right: 0;
    background: url(/assets/images/decoration-1.svg) no-repeat;
  }
}

.decoration.v2 {
  --width: 38%;
  --offset: 17%;
  --margin: calc(var(--width) - var(--offset));

  padding-left: var(--margin);
  padding-bottom: 5%;

  &:after {
    width: var(--width);
    aspect-ratio: 255/252;
    bottom: 0;
    left: 0;
    background: url(/assets/images/decoration-2.svg) no-repeat;
  }
}

.decoration.v3 {
  --width: 48%;
  --offset: 31%;
  --margin: calc(var(--width) - var(--offset));

  padding-right: var(--margin);
  padding-bottom: 17%;

  &:after {
    width: var(--width);
    aspect-ratio: 1/1;
    bottom: 0;
    right: 0;
    background: url(/assets/images/decoration-3.svg) no-repeat;
  }
}

.decoration.v4 {
  padding: 14% 10% 20% 0;
  z-index: 0;

  &:after {
    aspect-ratio: 522/518;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(/assets/images/decoration-4.svg) no-repeat;
    z-index: -1;
  }
}


.decoration.v5 {
  --width: 40%;
  --offset: 29%;
  --margin: calc(var(--width) - var(--offset));

  padding-right: var(--margin);
  padding-bottom: 7%;

  &:after {
    width: var(--width);
    aspect-ratio: 284/246;
    bottom: 0;
    right: 0;
    background: url(/assets/images/decoration-5.svg) no-repeat;
  }
}


.decoration.v6 {
  --width: 38%;
  --offset: 19%;
  --margin: calc(var(--width) - var(--offset));

  padding-left: var(--margin);
  padding-bottom: 7%;

  &:after {
    width: var(--width);
    aspect-ratio: 248/241;
    bottom: 0;
    left: 0;
    background: url(/assets/images/decoration-6.svg) no-repeat;
  }
}


.decoration.v7 {
  --width: 32%;
  --offset: 18%;
  --margin: calc(var(--width) - var(--offset));

  padding-right: var(--margin);
  padding-bottom: 5%;

  &:after {
    width: var(--width);
    aspect-ratio: 221/230;
    bottom: 0;
    right: 0;
    background: url(/assets/images/decoration-7.svg) no-repeat;
  }
}


//
// INLINE DECORATIONS
//
.with-flavor-drops-v1:before {
  content: '';
  display: inline-block;
  position: relative;
  top: .1em;
  width: .8em;
  aspect-ratio: 404/414;
  background: url(/assets/images/flavor-drops-1.svg);
  margin-right: .75rem;
}
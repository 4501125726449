input.form-control, textarea.form-control {
  text-align: center;
}
input::placeholder, textarea::placeholder {
  color: $gray-700;
}


.btn {
  text-transform: uppercase;
  letter-spacing: 1px;
}
.btn-outline-teal { color: inherit }


// Targeting only direct <labeL> (children) to not style any nested ones, related to radio and checkboxes.
.form-group > label {
  display: block;
  font-size: $input-btn-font-size;
}


// Custom style for the inline radio-buttons group that,
// like the "Region of Request" field on the Contact Us form.
label.form-check-inline-group {
  text-align: center;

  + div { text-align: center; }
}


// Tighten margins in a grid inside a form.
form {
  .row {
    margin-left: -$form-grid-gutter-width;
    margin-right: -$form-grid-gutter-width;
  }
  .form-group[class*="col-"] {
    padding-left: $form-grid-gutter-width;
    padding-right: $form-grid-gutter-width;
  }
}


// Success message
.form-success {
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  padding: 1rem;
  border: .6rem solid $green;
  background-color: $green-light;
  margin-top: 2rem;

  @include media-breakpoint-up(md) { font-size: 3rem }
  @include media-breakpoint-down(sm) {
    br { display: none }
  }
}

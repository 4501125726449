:root {
  --transition-duration: .15s;
}


// Colors
$green: #a1cc45;
$green-light: #e6f6a2;
$green-gradient: #f3fbd1; // $green-light / 0.5
$purple: #711c66;
$purple-light: #a72178;
$red: #d9213b;
$orange: #fa9502;
$teal: #008c97;
$blue-light: #c3e9ed;
$brown: #864323;

$colors: (
  "green": $green,
  "green-light": $green-light,
  "purple": $purple,
  "purple-light": $purple-light,
  "red": $red,
  "orange": $orange,
  "teal": $teal,
  "blue-light": $blue-light,
  "brown": $brown
);

$primary: $green;
$secondary: $purple;


// Typography
$font-family-base: Calibri, sans-serif;
$font-size-base: 1.125rem; // 18px (1.0625rem is 17px)
$lead-font-weight: normal;

$h1-font-size: 4rem;
$h2-font-size: 3rem;
$h3-font-size: 2.5rem;
$h4-font-size: $font-size-base * 1.75;
$headings-line-height: 1;
$headings-font-weight: 300;

$link-decoration: none;
$link-hover-decoration: underline;


// Navbar
$navbar-padding-y: 0;
$navbar-brand-padding-y: 0;
$navbar-nav-link-padding-x: clamp(.55rem, .9vw, 1.5rem);
$nav-link-padding-y: .25rem;
$nav-link-transition: color .15s, background-color .15s, border-color .15s;
$navbar-light-color: white;
$navbar-light-active-color: white;
$navbar-light-hover-color: $navbar-light-active-color;

$dropdown-min-width: 100%;
$dropdown-padding-y: 0;
$dropdown-link-color: $navbar-light-active-color;
$dropdown-bg: transparent;
$dropdown-border-width: 0;

$navbar-light-toggler-border-color: transparent;
$navbar-toggler-focus-width: 0;


// Breadcrumbs
$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0;
$breadcrumb-margin-bottom: 2rem;


// General
$border-radius: 0;
$border-radius-sm: 0;
$enable-validation-icons: false;


// Buttons & Inputs
$input-btn-font-size: 24px;
$input-btn-line-height: 1.1;
$input-btn-focus-box-shadow: 0;

$input-btn-padding-x: 2rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-padding-y: .5rem;
$input-btn-padding-y-sm: $input-btn-padding-y;
$input-padding-y: .75rem;

$input-color: black;
$input-bg: $green-light;
$input-border-color: transparent;
$input-focus-border-color: $primary;



// Grid
$form-grid-gutter-width: 10px;


// Alerts
$alert-color-level: 0;
$alert-padding-x: 0;


// Pagination
$pagination-bg: transparent;
$pagination-active-bg: $pagination-bg;
$pagination-hover-bg: $pagination-bg;

$pagination-padding-y: 0;
$pagination-padding-x: .25rem;

$pagination-active-color: $primary;

$pagination-border-color: $primary;

// Carousel
$carousel-indicator-width: 9px;
$carousel-indicator-height: 9px;
$carousel-indicator-spacer: 6px;
$carousel-indicator-opacity: .6;
$carousel-indicator-active-bg: $purple;
$carousel-caption-padding-y: 0;


// Card
$card-border-width: 6px;
//
// The shared styles between the frontend and the Redactor field in the backend.
//

@each $color, $value in $colors {
  .text-#{$color} { color: $value }
}

//
// COMPATIBILITY WITH LEGACY CODE
//
.abelei {
  color: black;
  font-weight: bold;

  > strong { color: $red }
}
:root {
  --logo-height-desktop: 170px;
  --main-pt-full: var(--logo-height-desktop);
  --main-pt-small: calc(var(--logo-height-desktop) / 3);
}


main {
  padding-top: var(--main-pt-small);
  @include media-breakpoint-up(lg) { padding-top: var(--main-pt-full) }
}


#header {
  --logo-height-mobile: 100px; // Used for the mobile and shrunk header on desktop.
  --header-bg-height-desktop: 148px;
  --header-bg-mobile-mt: 45px;
  --navbar-collapse-height: 34px; // Calculated height of the .navbar-collapse.
  --navbar-collapse-desktop-mt: calc(var(--logo-height-desktop) - var(--header-bg-height-desktop) + var(--navbar-collapse-height) / 2);
  --navbar-collapse-mobile-mt: 36px;
  // "-5px" shifts the navbar for 5px down to make more room between the button and the stripes.
  --navbar-collapse-desktop-shrunk-mt: calc(var(--logo-height-mobile) - var(--header-bg-height-desktop) + var(--header-bg-mobile-mt) - 5px + var(--navbar-collapse-height) / 2);
  --bs-scroll-height: calc(100vh - var(--logo-height-mobile) + var(--navbar-collapse-mobile-mt));


  position: fixed;
  width: 100%;
  z-index: 3; // Swiper pagination buttons are z-index: 2
  background: url(/assets/images/header-green-swoop.svg) center -10px repeat-x;
  background-size: auto var(--header-bg-height-desktop);
  transition: background-position-y var(--transition-duration);

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 20px;
    background: url(/assets/images/green-stripes.svg) center top repeat-x;
    background-size: auto 100%;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, .3));
  }

  .nav-item { text-transform: lowercase }
}

.navbar-brand {
  z-index: 1;
  img {
    height: var(--logo-height-desktop);
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, .3));
    transition: height var(--transition-duration)
  }
}


// Adds padding to the nav links on the mobile breakpoint.
// By default, bootstraps sets the padding to 0, except on the larger breakpoints, which didn't look good in the dropdown.
.navbar-nav .nav-link {
  padding-inline: $dropdown-item-padding-x;
}


@include media-breakpoint-down(lg) {
  #header {
    .nav-item { margin-bottom: .5rem }
    .nav-link { font-weight: bold }
  }
  .navbar-collapse {
    background-color: $green;
    margin-inline: calc(#{$container-padding-x} * -1);
  }
  .dropdown-menu {
    display: block;
    padding-left: $dropdown-item-padding-x;
  }
}


@include media-breakpoint-up(lg) {
  .dropdown-menu { border-top: .25rem solid transparent }
  .dropdown:hover .dropdown-menu { display: block }
}


/** MENU COLORS
 */
.nav-item-purple {
  &:hover .nav-link, .nav-link.active, &:hover .dropdown-menu { background-color: $purple }
  .dropdown-item.active, .dropdown-item:hover { background-color: scale-color($purple, $lightness: 10%) }
}
.nav-item-red {
  &:hover .nav-link, .nav-link.active, &:hover .dropdown-item { background-color: $red }
  .dropdown-item.active, .dropdown-item:hover { background-color: scale-color($red, $lightness: 10%) }
}
.nav-item-orange {
  &:hover .nav-link, .nav-link.active, &:hover .dropdown-menu { background-color: $orange }
  .dropdown-item.active, .dropdown-item:hover { background-color: scale-color($orange, $lightness: 10%) }
}
.nav-item-purple-light {
  &:hover .nav-link, .nav-link.active, &:hover .dropdown-menu { background-color: $purple-light }
  .dropdown-item.active, .dropdown-item:hover { background-color: scale-color($purple-light, $lightness: 10%) }
}
.nav-item-brown {
  &:hover .nav-link, .nav-link.active, &:hover .dropdown-menu { background-color: $brown }
  .dropdown-item.active, .dropdown-item:hover { background-color: scale-color($brown, $lightness: 10%) }
}
.nav-item-teal {
  &:hover .nav-link, .nav-link.active, &:hover .dropdown-menu { background-color: $teal }
  .dropdown-item.active, .dropdown-item:hover { background-color: scale-color($teal, $lightness: 10%) }
}


/** Mobile, scrolling and shrinking header styles.
 */
// The "shrunk" mixin holds common styles for the mobile and shrunk header on desktop.
@mixin shrunk {
  #header { background-position-y: calc(var(--header-bg-mobile-mt) * -1) }
  .navbar-brand img { height: var(--logo-height-mobile) }
  .navbar-toggler { margin-top: calc(var(--navbar-collapse-desktop-shrunk-mt) * -1) }
}

#scroll-tracker {
  position: absolute;
  top: 30px;
  width: 1px;
  height: 1px;
}
.page-scrolling { @include shrunk }

@include media-breakpoint-down(lg) {
  @include shrunk;
  .navbar-collapse { margin-top: calc(var(--navbar-collapse-mobile-mt) * -1) }
  .navbar-nav {
    padding: calc(var(--navbar-collapse-mobile-mt) + 1rem) 1rem .5rem;
  }
}

@include media-breakpoint-up(lg) {
  .navbar-collapse {
    margin-top: calc(var(--navbar-collapse-desktop-mt) * -1);
    transition: margin-top var(--transition-duration);
  }
  .page-scrolling .navbar-collapse { margin-top: calc(var(--navbar-collapse-desktop-shrunk-mt) * -1) }
}
